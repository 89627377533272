<template>

	<div class="iwleave">
<!--		<rx-nav-bar class="navbar" title="我要请假" fixed left-arrow @click-left="leftReturn" />-->
		<rxNavBar  title="我要请假"></rxNavBar>
		<!-- 类型 -->
		<div class="type" @click="vacationTypeShow = true">
			<div :class="vacationTypeMap.dictionaryTitle  ? 'dian':'diangray'">•</div>
			<div class="tptitle">类型</div>
			<div class="shu">|</div>
			<div :class="!vacationTypeMap.dictionaryTitle ?'tpdetailgray':'tpdetail'">{{vacationTypeMap.dictionaryTitle ? vacationTypeMap.dictionaryTitle : '请选择'}}</div>
			<div class="tpimg"><img src="../../../assets/images/triangle.png" alt=""></div>
		</div>
		<div  class="types">
			<!-- 开始时间 -->
				<div class="type-first" @click="beginDateShow = true">
					<div :class="vacationBeginTime && vacationBeginTimeDate ? 'dian':'diangray'">•</div>
					<div class="tptitle">开始时间</div>
					<div class="shu">|</div>
					<div class="psdetail-first">{{vacationBeginTime}}</div>
					<div class="tmimg"><img src="../../../assets/images/timepick.png" ></div>
				</div>

			<div class="type-second" @click="beginTimeDateShow = true">
				<div class="tptitle-second"></div>
				<div :class="!vacationBeginTimeDate ?'tpdetailgray':'tpdetail'">{{vacationBeginTimeDate ? vacationBeginTimeDate : '请选择'}}</div>
				<div class="tpimg"><img src="../../../assets/images/triangle.png" alt=""></div>
			</div>
		</div>
		<div  class="types">
			<!-- 结束时间 -->
				<div class="type-first" @click="endDateShowFun">
					<div :class="vacationEndTime && vacationEndTimeDate? 'dian':'diangray'">•</div>
					<div class="tptitle">结束时间</div>
					<div class="shu">|</div>
					<div class="psdetail-first">{{vacationEndTime}}</div>
					<div class="tmimg"><img src="../../../assets/images/timepick.png" alt=""></div>
				</div>

			<div class="type-second" @click="endTimeDateShow = true">
				<div class="tptitle-second"></div>
				<div :class="!vacationEndTimeDate?'tpdetailgray':'tpdetail'">{{vacationEndTimeDate?vacationEndTimeDate: '请选择'}}</div>
				<div class="tpimg"><img src="../../../assets/images/triangle.png" alt=""></div>
			</div>
		</div>
		<!-- 请假时长 -->
		<div class="type" >
			<div :class="vacationDay != '' ? 'dian':'diangray'">•</div>
			<div class="tptitle">请假时长</div>
			<div class="shu">|</div>
			<div class="vacationDay">
				<input type="number" v-model="vacationDay" readonly>天
			</div>
<!--			<div class="day">天</div>-->
		</div>
		<!-- 工作 交接人 -->
		<div class="type" @click="handoverStaffShow = true">
			<div :class="handoverStaffMap.staffName  ? 'dian' : 'diangray' ">•</div>
			<div class="tptitle">工作交接人</div>
			<div class="shu">|</div>
			<div :class="!handoverStaffMap.staffName ?'psdetailgray':'psdetail'">{{handoverStaffMap.staffName ? handoverStaffMap.staffName : '请选择'}}</div>
			<div class="tpimg"><img src="../../../assets/images/triangle.png" alt=""></div>
		</div>

		<div class="changeCause">
			<div class="changeCauseCentre">
				<div class="changeCauseCentreTitle">
					<div :class="remarks != '' ? 'dian':'diangray'">•</div>
					<div class="part-inputpart-row-headerA">请假理由</div>
					<span class="notRequired">(必填)</span>
				</div>
				<textarea class="textareaInput" v-model="remarks" placeholder="请输入请假理由"></textarea>
			</div>
		</div>
<!--		<van-uploader  class="uploadDiv" v-model="fileList" :after-read="afterRead" image-fit="contain">-->
<!--			<div class="upload">-->
<!--				<img src="../../../assets/images/grayAdd.png" class="upload_icon">-->
<!--				<div class="upload_font">上传图片</div>-->
<!--			</div>-->
<!--		</van-uploader>-->
		<van-uploader class="uploadDiv"  v-model="fileList" multiple
					  :after-read="afterReadTest" upload-icon="plus" :max-count="10"></van-uploader>
		<!-- 提交 -->
		<button :class="vacationTypeMap.dictionaryTitle&& vacationBeginTime && vacationBeginTimeDate && vacationEndTime && vacationEndTimeDate &&vacationDay&&remarks&&handoverStaffMap.staffName ? 'confrim' : 'confrimgray'"
				:disabled="!handoverStaffMap.staffName||!vacationTypeMap.dictionaryTitle|| !vacationBeginTime ||!vacationEndTime||!vacationDay||!remarks " @click="saveLeaveApply">
			提 交
		</button>
<!--		类型的弹窗-->
		<van-popup v-model="vacationTypeShow" round position="bottom">
			<van-picker title="选择类型" show-toolbar :columns="vacationTypeList" @confirm="selectTypeFun" @cancel="vacationTypeShow = false" value-key="dictionaryTitle" />
		</van-popup>
<!--		开始时间的弹窗-->
		<van-popup v-model="beginDateShow" position="bottom">
			<van-datetime-picker visible-item-count="5"  v-model="startDate" type="date" title="选择年月日" item-height="35px" @confirm="beginDateFun" @cancel="beginDateShow = false"/>
		</van-popup>
		<!--		结束时间的弹窗-->
		<van-popup v-model="endDateShow  " position="bottom">
			<van-datetime-picker visible-item-count="5"   :min-date="startDate" v-model="endDate" type="date" title="选择年月日" item-height="35px" @confirm="endDateFun" @cancel="endDateShow = false"/>
		</van-popup>

		<!--上下午-->
		<van-popup v-model="beginTimeDateShow" round position="bottom">
			<van-picker title="选择时间段" show-toolbar :columns="startTimeStage" @confirm="selectStartTimeStage" @cancel="beginDateDateShow = false" value-key="dictionaryTitle" />
		</van-popup>

		<van-popup v-model="endTimeDateShow" round position="bottom">
			<van-picker title="选择时间段" show-toolbar :columns="endTimeStage" @confirm="selectEndTimeStage" @cancel="endTimeDateShow = false" value-key="dictionaryTitle" />
		</van-popup>

<!--		交接人的弹窗-->
		<van-popup v-model="handoverStaffShow" round position="bottom">
			<van-picker title="选择交接人" show-toolbar :columns="handoverStaffList" @confirm="handoverStaffFun" @cancel="handoverStaffShow = false" value-key="staffName" />
		</van-popup>
		<loading-flag v-if="loadingFlag"></loading-flag>
	</div>

</template>

<script>
	import {Button, DatetimePicker, DropdownItem, DropdownMenu, NavBar, Popup, Picker, Uploader, Calendar} from "vant";
	Vue.use(Popup, Picker);
	Vue.use(Calendar);
	import {
		base64Upload,
		queryBaseData, queryStaffList, saveLeaveApply, staffVacationHistoryDetail,
	} from "../../../getData/getData";
	import {
		getStaffId,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import LoadingFlag from "../../afterRent/maintain/LoadingFlag";
    import util from "../../contract/util2";

	export default {
		components: {
			LoadingFlag,
			[DatetimePicker.name]: DatetimePicker,
			[NavBar.name]: NavBar,
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem,
			[DatetimePicker.name]: DatetimePicker,
			[Button.name]: Button,
			[Popup.name]: Popup,
			[Picker.name]: Picker,
			[Uploader.name]: Uploader,
			rxNavBar,
		},
		name: "myOrder",
		data() {
			return {
				loadingFlag : false,
				//上传图片
				fileList : [],
			//	请假类型数组
				vacationTypeList :[],
				vacationTypeMap : {},
				handoverStaffList: [],
				handoverStaffMap :{},
				vacationType : '',
			//	开始时间
				vacationBeginTime : '',
				//结束时间
				vacationEndTime : '',
			//	交接人ID
				handoverStaff_id : '',
			//	时长
				vacationDay: '',
			//	请假事由
				remarks : '',
				//弹窗控制
				vacationTypeShow : false,
				beginDateShow : false,
				endDateShow : false,
				handoverStaffShow : false,
				startDate : new Date(),
				endDate : new Date(),
				maxDate : '',
                startTimeStage:[
                    {dictionaryValue: '1',dictionaryTitle:'上午'},
                    {dictionaryValue: '3',dictionaryTitle:'下午'},
                ],
                endTimeStage:[
                    {dictionaryValue: '2',dictionaryTitle:'上午'},
                    {dictionaryValue: '4',dictionaryTitle:'下午'},
				],
                vacationBeginTimeDate:'',
                vacationEndTimeDate:'',
                vacationBeginTimeDateValue:'',
                vacationEndTimeDateValue:'',
                beginTimeDateShow:false,
                endTimeDateShow:false,
			}
		},

		created() {

			this.initDB()
			this.initStaffData()
		},
		//初始化

		mounted() {
			if(this.$route.query.id != undefined){
				this.id = this.$route.query.id
				this.staffVacationHistoryDetail();

			}
		},
		methods: {
			pathStrToMap(oldPathList){
				let pathLength = oldPathList.length
				var arr = new Array()
				for(let i = 0; i < pathLength; i++){
					let str = pathLength[i]
					let obj = {}
					obj.push('path',str)
					arr[i] = obj
				}
				return arr;
			},
			//修改初始化
			staffVacationHistoryDetail(){
				let that = this
				let initData = {
					id: that.id
				}
				staffVacationHistoryDetail(initData).then(function (response) {
					responseUtil.dealResponse(that, response, () => {
						let pathLength =  response.data.data.pathList.length
						if (response.data.data.pathList && response.data.data.pathList.length){
							for (let i = 0; i < pathLength; i++) {
								that.fileList.push({
									content: response.data.data.pathList[i].path,
									url: response.data.data.pathList[i].path
								})
							}
						}
						that.id = response.data.data.vacationDetail.id;   //请假id
						that.handoverStaffMap.id = response.data.data.vacationDetail.handoverStaff_id;  //交接员工id
						that.handoverStaffMap.staffName = response.data.data.vacationDetail.handoverStaffName;  //交接员工姓名
						that.vacationTypeMap.dictionaryValue = response.data.data.vacationDetail.vacationType;  //请假类型
						that.vacationTypeMap.dictionaryTitle = that.findValueToTitle(that.vacationTypeList,response.data.data.vacationDetail.vacationType);  //请假类型
						that.vacationDay = response.data.data.vacationDetail.vacationDay;  //请假时长
						that.remarks = response.data.data.vacationDetail.remarks;  //请假事由
						let vacationBeginTime = response.data.data.vacationDetail.vacationBeginTime.substring(0,10);  //开始日期
						let vacationEndTime = response.data.data.vacationDetail.vacationEndTime.substring(0,10); //结束日期
						that.vacationBeginTime = that.formatDate(new Date(vacationBeginTime));  //开始日期
						that.vacationEndTime = that.formatDate(new Date(vacationEndTime)); //结束日期
						let vacationBeginTimeStr = response.data.data.vacationDetail.vacationBeginTime;  //开始时间
						let vacationEndTimeStr = response.data.data.vacationDetail.vacationEndTime;     //结束时间
						that.vacationBeginTimeDateValue = that.caleTimeStage(vacationBeginTimeStr)  //开始时段value
						that.vacationEndTimeDateValue = that.caleTimeStage(vacationEndTimeStr)     //结束时段value
						that.vacationBeginTimeDate = that.findValueToTitle(that.startTimeStage,that.caleTimeStage(vacationBeginTimeStr))  //开始时段字样
						that.vacationEndTimeDate = that.findValueToTitle(that.endTimeStage,that.caleTimeStage(vacationEndTimeStr))      //结束时段字样
					})
				})
			},
			formatDate(dateTime) {
				let year = `${dateTime.getFullYear()}`;
				let month  = `${dateTime.getMonth() + 1}` ;
				let date = `${dateTime.getDate()}` ;
				let hours = `${dateTime.getHours()}` ; ;
				let minutes = `${dateTime.getMinutes()}` ; ;
				if(month < 10){
					month = `0${dateTime.getMonth() + 1}`
				}
				if(date < 10){
					date = `0${dateTime.getDate()}`
				}
				if(hours < 10){
					hours = `0${dateTime.getHours()}`
				}
				if( minutes < 10){
					minutes = `0${dateTime.getMinutes()}`
				}
				return year+ '/' + month+ '/' + date;

			},
			//根据完整请假时间算出时段，用于修改初始化
			caleTimeStage(dateAndTime){
				let tempState = dateAndTime.indexOf(' ');
				//截取时间
				let hour = dateAndTime.substring(tempState + 1,tempState + 3)
				let timeState;
				if(hour == '09'){
					timeState = '1';
				}else if(hour == '13'){
					timeState = '3';
				}else if(hour == '12'){
					timeState ='2';
				}else if(hour == '18'){
					timeState = '4';
				}
				return timeState;
			},
			afterReadTest(file){
				// this.loadingFlag = true;
				let fileLength = parseInt(file.length) - parseInt(1)
				if(file.length > 1){
					for(let i = 0; i<file.length;i++){
						let fileDetail = file[i];
						this.afterRead(fileDetail,i,fileLength);
					}
				}else{
					this.afterRead(file);
				}
			},
			afterRead(file,i,fileLength) {
				let that = this
				let initData = {
					base64: file.content
				}
				that.loadingFlag = true
				base64Upload(initData).then(function (response) {
					responseUtil.dealResponse(that, response, () => {
						file.path = response.data.data.path
						if(i == fileLength){
							that.loadingFlag = false
						}
					})
				})
			},
			endDateShowFun(){
				if(this.vacationBeginTime){
					this.endDateShow = true
				}
			},
			//初始化字典数据
			initDB(){
				var that = this
				let initData = {}
				initData.fdName = ["VACATIONTYPEMAP"]
				queryBaseData(initData).then(function (response) {
					responseUtil.dealResponse(that, response, () => {
						//请假类型
						that.vacationTypeList = response.data.data.VACATIONTYPEMAP
					})
				})
			},
			findValueToTitle(list,value){
				for(let i = 0 ; i < list.length;i ++){
					let obj = list[i];
					if(obj.dictionaryValue == value){
						return obj.dictionaryTitle;
					}
				}
				return '';

			},
			//类型选择
			selectTypeFun(item) {
				this.vacationTypeMap = item //将值显示出来
				this.vacationTypeShow = false //关掉弹起
			},
			//开始时间
			beginDateFun(item){
				var date = new Date(item)
				this.vacationBeginTime = date.getFullYear()+'/'+(date.getMonth()+1)+'/'+date.getDate()
				this.beginDateShow = false
                this.calculateDays()
			},
			//结束时间
			endDateFun(item){
				var date = new Date(item)
				this.vacationEndTime = date.getFullYear()+'/'+(date.getMonth()+1)+'/'+date.getDate()
				this.endDateShow = false
                this.calculateDays()
			},
			//开始时间上下午
            selectStartTimeStage(item){
                this.vacationBeginTimeDate = item.dictionaryTitle //将值显示出来
                this.vacationBeginTimeDateValue = item.dictionaryValue
                this.beginTimeDateShow = false //关掉弹起
                this.calculateDays()
            },
			//结束时间上下午
            selectEndTimeStage(item){
                this.vacationEndTimeDate = item.dictionaryTitle //将值显示出来
                this.vacationEndTimeDateValue = item.dictionaryValue
                this.endTimeDateShow = false //关掉弹起
                this.calculateDays()
            },
			//根据选择的时间计算天数
			calculateDays(){
                let that = this;

                if(that.vacationBeginTime && that.vacationEndTime && that.vacationBeginTimeDateValue && that.vacationEndTimeDateValue) {
                    if(that.vacationBeginTime == that.vacationEndTime && Number(that.vacationBeginTimeDateValue) > Number(that.vacationEndTimeDateValue)){
                        responseUtil.alertMsg(this, "开始时间不能大于结束时间！");
                        return
                    }
                    let wholeDays = Math.round(Number(util.getDaysSum(that.vacationBeginTime,that.vacationEndTime))) - 1;

                    let incompleteDays = 0;

                    if((that.vacationBeginTimeDateValue == 1 && that.vacationEndTimeDateValue == 2) || (that.vacationBeginTimeDateValue == 3 && that.vacationEndTimeDateValue == 4)){
                        incompleteDays = 0.5;
                    }else if(that.vacationBeginTimeDateValue == 1 && that.vacationEndTimeDateValue == 4){
                        incompleteDays = 1;
                    }
                    that.vacationDay = wholeDays + incompleteDays;
                }
			},
			//根据所选上下午拼凑时间
            dealVacationTime(){
			    var that = this;
				//修改开始时间
                if(that.vacationBeginTimeDateValue == 1){
                    that.vacationBeginTime = that.vacationBeginTime + ' 09:00:00';
                }else if(that.vacationBeginTimeDateValue == 3){
                    that.vacationBeginTime = that.vacationBeginTime + ' 13:00:00';
                }
                //修改结束时间
                if(that.vacationEndTimeDateValue == 2){
                    that.vacationEndTime = that.vacationEndTime + ' 12:00:00';
                }else if(that.vacationEndTimeDateValue == 4){
                    that.vacationEndTime = that.vacationEndTime + ' 18:00:00';
                }
            },
			//选择交接人
			handoverStaffFun(item){
				this.handoverStaffMap = item
				this.handoverStaffShow = false
			},
			//查询员工
			initStaffData:function() {
				var that = this
				let initData = {}
				queryStaffList(initData).then(function (response) {
					responseUtil.dealResponse(that, response, () => {
						that.handoverStaffList = response.data.data.data
					})
				})
			},
			saveLeaveApply(){
				//判断跨年或跨月
				if((this.vacationBeginTime.split('/'))[1]==(this.vacationEndTime.split('/'))[1]&&(this.vacationBeginTime.split('/'))[0]==(this.vacationEndTime.split('/'))[0]){
					let that = this
                    that.dealVacationTime()
					let initData = {}
					initData.user_id = globaluserId()
					initData.id = that.id
					initData.staff_id = getStaffId()
					initData.handoverStaff_id = that.handoverStaffMap.id
					initData.vacationType = that.vacationTypeMap.dictionaryValue
					initData.vacationBeginTime =  new Date(that.vacationBeginTime)
					initData.vacationEndTime = new Date(that.vacationEndTime)
					initData.vacationDay = that.vacationDay
					initData.remarks = that.remarks
					// initData.paths = that.pathMapToStr(that.fileList)
					if (that.fileList.length) {
						let tempPath = []
						for (let i = 0; i < that.fileList.length; i++){
							let newUrl = that.fileList[i].path != undefined ? that.fileList[i].path : that.fileList[i].url
							tempPath.push({path: newUrl })
						}
						initData.paths = that.pathMapToStr(tempPath)
					}
					saveLeaveApply(initData).then(function (response) {
						responseUtil.dealResponse(that,response,()=>{
							if(response.data.code==0){
								responseUtil.alertMsg(that,'刷新成功')
								that.$router.go(-1)
							}
						})
					})
				}else{
					responseUtil.alertMsg(this,'不能跨月或跨年请假')
				}
			},
			pathMapToStr(oldPathList){
				let pathLength = oldPathList.length
				var arr = new Array()
				for(let i = 0; i < pathLength; i++){
					arr[i] = oldPathList[i].path
				}
				return arr;
			},
		},
	}
</script>

<style scoped lang="less">

	.iwleave{
		padding-bottom: 50px;
	}
	.type {
		width: 90%;
		background-color: white;
		height: 50px;
		/*line-height: 50px;*/
		border-radius: 8px;
		margin: 15px auto;
		display: flex;
		align-items: center;
	}

	.types {
		width: 90%;
		height: 50px;
		/*line-height: 50px;*/
		margin: 15px auto;
		display: flex;
		align-items: center;
	}

	.type-first {
		width: 73%;
		background-color: white;
		height: 50px;
		/*line-height: 50px;*/
		border-radius: 8px;
		display: flex;
		align-items: center;
	}
	.type-second {
		 width: 24%;
		 background-color: white;
		 height: 50px;
		 /*line-height: 50px;*/
		 border-radius: 8px;
		 display: flex;
		 align-items: center;
		margin-left: 10px;
	}

	.dian,
	.diangray {
		color: #ff5809;
		font-size: 25px;
		margin: auto 10px;
	}

	.diangray {
		color: gray;
	}

	.tptitle {
		font-weight: bold;
		font-size: 14px;
		color: black;
	}
	.tptitle-second {
		width: 10%;
	}

	.shu {
		font-size: 14px;
		color: #efefef;
		margin: 0 10px ;
	}

	.tpdetail,
	.tpdetailgray {
		font-size: 14px;
		width: 68%;
	}

	.tpdetailgray {
		color: gray;
	}
	.psdetail,
	.psdetailgray {
		font-size: 14px;
		width: 56%;
	}

	.psdetailgray {
		color: gray;
	}

	.psdetail-first {
		font-size: 14px;
		width: 42%;
	}

	.tpimg img {
		width: 9px;
		height: 9px;
	}

	.tmimg {
		width: 20px;
		height: 20px;
		margin-left: 5px;
		display: flex;
	img {
		width: 100%;
		height: 100%;
	}


	}

	.time {
		height: 50px;
		width: 90%;
		/*margin: 0 auto 15px;*/
		margin: 0 auto 15px;
	}

	.begintime {
		/*float: left;*/
		width: 100%;
		background-color: white;
		height: 50px;
		border-radius: 8px;
	}

	.half {
		height: 50px;
		background-color: white;
		width: 29%;
		/*float: right;*/
		border-radius: 8px;
	}

	.part-inputpart-row {
		width: 345px;
		/*float: left;*/
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: white;
		height: 50px;
		border-radius: 8px;
		margin: 0 5px 15px 15px;
	}
	.changeCause {
		width: 100%;
		margin-top: 15px;
	}

	.changeCauseCentre {
		margin: 0 auto;
		width: 90%;
		background-color: #FFFFFF;
		padding: 10px 0px;
		border-radius: 8px;
	}

	.changeCauseCentreTitle {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: left;
	}

	.part-inputpart-row-headerA {
		font-weight: bold;
		font-size: 14px;
		color: black;
	}

	.textareaInput {
		display: block;
		width: 85%;
		margin: 9px auto 5px;
		height: 70px;
		border-radius: 8px;
		background-color: #f7f7f7;
		border: none;
		resize: none;
		font-size: 14px;
		padding: 10px;
	}

	.notRequired {
		color: rgba(153, 153, 153, 1);
		font-size: 10px;
		font-family: PingFangSC-Regular;
	}

	.confrim {
		display: block;
		margin: 0 auto;
		margin-top: 40px;
		height: 50px;
		width: 90%;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: white;
		border-radius: 8px;
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		line-height: 50px;
		border: none;
	}

	.confrimgray {
		display: block;
		margin: 0 auto;
		margin-top: 40px;
		height: 50px;
		width: 90%;
		background-color: #d9d9d9;
		color: white;
		border-radius: 8px;
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		line-height: 50px;
		border: none;
	}
	.vacationDay{
		font-size: 14px;
		width: 65%;
		overflow: hidden;
		input{
			/*height: 30px;*/
			width: 85%;
			border: none;
		}
	}
	.handover{
		width: 25px;
		height: 40px;
		float: left;
	}
	/*上传图片*/
	.uploadDiv{
		display: flex;
		width: 87%;
		margin: 10px auto;
		border-radius: 8px;
		background-color: white;
	}
	.upload{
		border-radius: 10px;
		height: 90px;
		width:90px;
		background-color: #e6e6e6;
		margin-left: 10px;
		margin-top: 10px;
		float: left;
	}
	.upload_icon{
		height: 35px;
		width: 35px;
		margin:20px 10px 2px 27px;
	}
	.upload_font{
		height: 25px;
		font-size: 12px;
		line-height: 2px;
		margin-left: 23px;
		color: gray;
	}
</style>
